import { useEffect, useState } from "react";
import { Icon } from "./Icon";

export const App = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({ text: "", isError: false });

  const isValidEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = async (email: string) => {
    console.log(sendEmail);
    return fetch("/waitlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  };

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      setMessage({
        text: "Please enter a valid email address.",
        isError: true,
      });
      return;
    }

    const response = await sendEmail(email);
    if (response.ok) {
      setMessage({
        text: "You've been added to the waitlist!",
        isError: false,
      });
    } else {
      setMessage({
        text: "An error occurred. Please try again.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    };

    window.addEventListener("keydown", handleEnter);
    return () => window.removeEventListener("keydown", handleEnter);
  });

  return (
    <div
      id="sea-background-image"
      // className="min-h-screen w-full bg-gradient-to-br from-yellow-600 to-orange-100 flex items-center justify-center p-4"
    >
      <div className="w-full max-w-lg bg-white p-8 rounded-lg shadow-md">
        <div className="flex flex-col items-center justify-center">
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Icon
              style={{ width: "50px", height: "50px", borderRadius: "16px" }}
            />
            <h1 className="text-4xl font-bold text-gray-800">
              Watch<span style={{ color: "#79978C" }}>Log</span>
            </h1>
          </div>
          <p className="text-gray-600 text-lg mb-6 text-center">
            Product observability made for developers
          </p>
          <div className="w-full flex flex-col gap-4">
            <input
              className="w-full px-4 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#EFE3CA]"
              type="email"
              placeholder="Enter your email"
              aria-label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="w-full text-white font-bold py-2 px-4 rounded-lg transition duration-300"
              style={{
                backgroundImage: "linear-gradient(to right, #79978C, #EFE3CA)",
              }}
              onClick={handleSubmit}
            >
              Join the Waitlist
            </button>
          </div>
          {message.text && (
            <p
              className={`mt-4 text-sm ${
                message.isError ? "text-red-600" : "text-green-600"
              }`}
            >
              {message.text}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;
