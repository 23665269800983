import { SVGProps } from "react";

export const Icon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2_19)">
        <rect width="500" height="500" fill="white" />
        <rect width="500" height="500" fill="#353535" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M420.755 313.126C395.089 382.527 328.322 432 250 432C171.089 432 103.908 381.78 78.6736 311.557L79.328 309.389L109.733 208.643C111.45 202.955 119.505 202.955 121.222 208.643L157.586 329.135C165.026 353.786 199.929 353.786 207.369 329.135L243.733 208.643C245.45 202.955 253.505 202.955 255.222 208.643L291.586 329.135C299.026 353.786 333.929 353.786 341.369 329.135L377.733 208.643C379.45 202.955 387.505 202.955 389.222 208.643L420.755 313.126ZM430.247 275.359L408.369 202.865C400.929 178.214 366.026 178.214 358.586 202.865L322.222 323.357C320.505 329.045 312.45 329.045 310.733 323.357L274.369 202.865C266.929 178.214 232.026 178.214 224.586 202.865L188.222 323.357C186.505 329.045 178.45 329.045 176.733 323.357L140.369 202.865C132.929 178.214 98.026 178.214 90.5864 202.865L69.4335 272.953C68.4875 265.435 68 257.775 68 250C68 149.484 149.484 68 250 68C350.516 68 432 149.484 432 250C432 258.605 431.403 267.071 430.247 275.359ZM450 250C450 360.457 360.457 450 250 450C139.543 450 50 360.457 50 250C50 139.543 139.543 50 250 50C360.457 50 450 139.543 450 250Z"
          fill="url(#paint0_linear_2_19)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2_19"
          x1="450"
          y1="50"
          x2="50"
          y2="450"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFE3CA" />
          <stop offset="1" stopColor="#79978C" />
        </linearGradient>
        <clipPath id="clip0_2_19">
          <rect width="500" height="500" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
